import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const LesOiseauxMigrateurs = () => (
  <Layout>
    <SEO title="Les Oiseaux migrateurs" description="Les créations de la Compagnie Ailes de Cire sur Toulouse, Nice et La Rochelle. Les Oiseaux migrateurs." />
    <section id="creations-banner">
      <h1>LES CRÉATIONS</h1>
    </section>

    <section className="main container">
        <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
            <li className="breadcrumb-item"><Link to="/les-creations/">Les créations</Link></li>
            <li className="breadcrumb-item active" aria-current="page">Les Oiseaux migrateurs</li>
        </ol>
        </nav>

        <div className="row align-items-center" style={{marginBottom: `2rem`}}>
        <div className="col-md-6 col-sm-12 mb-3">
            <Image filename="fontaine-deambulation.jpeg" alt="Les Oiseaux migrateurs, Clara Compain au bord de la fontaine, place Willson, Toulouse" title="Les Oiseaux migrateurs" />
        </div>
        <div className="col-md-6 col-sm-12">
        <h2>Les Oiseaux migrateurs</h2>
        <p>La compagnie Ailes de Cire part tous les étés en vadrouille de ville en ville envahir les rues afin d'y apporter de la joie, et quelques surprises.</p>
        <p>Les interprètes vous proposent des déambulations éphémères dans l'espace urbain.</p>
        <p>Afin de réussir à nous trouver, suivez nous sur Facebook, Instagram ou dans l'agenda les déambulations à venir.</p>
        </div>
      </div>
    </section>

  </Layout>
)

export default LesOiseauxMigrateurs
